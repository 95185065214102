import React from 'react';
import RequireLogin from '../components/Auth/RequireLogin';
import { Router } from '@reach/router';
import NotFound from '@components/Routing/NotFound';
import ClubInvoiceReport from '@presenters/web/pages/ClubInvoiceReport';
import { FEATURE_INVOICE, isEnabled } from '@utils/features';

const ClubInvoiceReportPage: React.FC<{
  pageContext: { languagePrefix: string };
}> = ({ pageContext: { languagePrefix } }) => {
  const isInvoiceEnabled = isEnabled(FEATURE_INVOICE);

  if (!isInvoiceEnabled) {
    return <NotFound />;
  }

  return (
    <RequireLogin>
      <Router basepath={`/${languagePrefix}`}>
        <ClubInvoiceReport path="/club-invoice-report/:clubId" />
      </Router>
    </RequireLogin>
  );
};

export default ClubInvoiceReportPage;
